import React from 'react'
import { LessonContent } from './LessonCommon'

class LessonList extends React.Component {
    constructor(props) {
        super(props)
        this.state = { lessons: [], page: 1, isLast: false }
        this.loadMore = this.loadMore.bind(this)
    }

    componentDidMount() {
        this.loadMore()
    }

    loadMore() {
        $.get(this.props.src, { page: this.state.page }, function (data) {
            if (data.length > 0) {
                this.setState((prevState, _props) => ({
                    page: prevState.page + 1,
                    lessons: prevState.lessons.concat(data)
                }))
            } else {
                this.setState({ isLast: true })
            }
        }.bind(this), 'json')
    }

    render() {
        return (
            <div>{
                this.state.lessons.map((lesson, i) => {
                    return <Lesson key={i} lesson={lesson} isAdmin={this.props.isAdmin} />
                })}
                <LoadMoreButton loadMore={this.loadMore} isLast={this.state.isLast} />
            </div>
        )
    }
}

const LoadMoreButton = (props) => (
    <div className="column">
        {(() => {
            if (props.isLast) {
                return null
            }
            return <a className="button is-primary is-fullwidth"
                onClick={props.loadMore}>加载更多</a>
        })()}
    </div>
)

const Lesson = (props) => (
    <div className="column">
        <div className="card">
            <LessonContent lesson={props.lesson} />
            <LessonFooter id={props.lesson.id} />
            <LessonAdmin id={props.lesson.id} isAdmin={props.isAdmin} />
        </div>
    </div>
)

const LessonFooter = (props) => (
    <footer className="card-footer">
        <ViewLessonBtn id={props.id} />
    </footer>
)

const LessonAdmin = (props) => (
    <footer className="card-footer">
        {(() => {
            if (props.isAdmin) {
                return (
                    <ViewAssignBtn id={props.id} />
                )
            }
        })()}
        {(() => {
            if (props.isAdmin) {
                return (
                    <ViewOrdersBtn id={props.id} />
                )
            }
        })()}
    </footer>
)

const ViewLessonBtn = (props) => (
    <p className="card-footer-item">
        <a href={"/activities/" + props.id}>查看活动</a>
    </p>
)

const ViewAssignBtn = (props) => (
    <p className="card-footer-item">
        <a href={"/activities/" + props.id + "/assigns"}>查看活动选项</a>
    </p>
)

const ViewOrdersBtn = (props) => (
    <p className="card-footer-item">
        <a href={"/activities/" + props.id + "/orders"}>查看订单</a>
    </p>
)

export default LessonList
