import "styles/application.scss";

import "core-js/stable";
import "regenerator-runtime/runtime";

require("jquery");
import * as moment from "moment";

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
