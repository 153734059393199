import React from 'react'
import {LessonContent} from './LessonCommon'

const UserLessonList = (props) => (
    <div className="container">
        {props.lessons.map((lesson, i) => {
            return <Lesson key={i} lesson={lesson} />
        })}
    </div>
)

const Lesson = (props) => (
    <div className="column">
        <div className="card">
            <LessonContent lesson={props.lesson} />
            <LessonFooter id={props.lesson.id} />
        </div>
    </div>
)

const LessonFooter = (props) => (
    <footer className="card-footer">
        <ViewScheduleBtn id={props.id} />
    </footer>
)

export const ViewScheduleBtn = (props) => (
    <p className="card-footer-item">
        <a href={"/user/activities/" + props.id}>查看活动选项</a>
    </p>
)

export default UserLessonList
