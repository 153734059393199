import React from 'react'

export const LessonContent = (props) => (
    <div className="card-content">
        <p className="subtitle">
            {props.lesson.name}
        </p>
        <ul>
            <li>
                <div className="level is-mobile">
                    <div className="level-left">活动时间</div>
                    <div className="level-right">
                        {(() => {
                            let m_begin = moment(props.lesson.begin_at)
                            let m_end = moment(props.lesson.end_at)
                            if (m_begin.isSame(m_end, 'day')) {
                                return (
                                    m_begin.format('M月D日 HH:mm') + "~" + m_end.format('HH:mm')
                                )
                            } else {
                                return (
                                    m_begin.format('M月D日 HH:mm') + "~" + m_end.format('M月D日 HH:mm')
                                )
                            }
                        })()}
                    </div>
                </div>
            </li>
            <li>
                <div className="level is-mobile">
                    <div className="level-left">费用</div>
                    <div className="level-right">
                        ¥ {(props.lesson.price / 100).toFixed(2)}
                    </div>
                </div>
            </li>
        </ul>
    </div>
)
