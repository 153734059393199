import React from 'react'

class VideoContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { categories: [], videos: [], cur_category_id: 0 }
        this.categorySelected = this.categorySelected.bind(this)
    }

    categorySelected(e) {
        let category_id = e.target.id
        this.setState({ cur_category_id: category_id })
        this.updateVideos(category_id)
    }

    updateVideos(category_id) {
        $.get(`/api/video_categories/${category_id}`, function (data) {
            this.setState({ videos: data })
        }.bind(this), 'json')
    }

    componentDidMount() {
        $.get('/api/video_categories', function (data) {
            if (data.length > 0) {
                let category_id = data[0].id
                this.setState({ categories: data, cur_category_id: category_id })
                this.updateVideos(category_id)
            }
        }.bind(this), 'json')
    }

    render() {
        return (
            <div>
                <CategoryList
                    categories={this.state.categories}
                    cur_category_id={this.state.cur_category_id}
                    categorySelected={this.categorySelected} />
                <VideoList videos={this.state.videos} />
            </div>
        );
    }
}

const CategoryList = (props) => (
    <div className="tabs is-toggle is-fullwidth">
        <ul>{
            props.categories.map((category, i) => {
                return <Category key={i}
                    category={category}
                    cur_category_id={props.cur_category_id}
                    categorySelected={props.categorySelected} />
            })}
        </ul>
    </div>
)

class Category extends React.Component {
    render() {
        let className = this.props.category.id == this.props.cur_category_id ? 'is-active' : ''
        return (
            <li className={className}>
                <a id={this.props.category.id} onClick={this.props.categorySelected}>
                    {this.props.category.name}
                </a>
            </li>
        )
    }
}

const VideoList = (props) => (
    <div className="container">
        {props.videos.map((video, i) => {
            return <Video key={i} video={video} />
        })}
    </div>
)

const Video = (props) => (
    <div className="column">
        <div className="card">
            <VideoContent video={props.video} />
            <VideoFooter id={props.video.id} />
        </div>
    </div>
)

const VideoContent = (props) => (
    <div className="card-content">
        <p className="subtitle">
            {props.video.name}
        </p>
    </div>
)

const VideoFooter = (props) => (
    <footer className="card-footer">
        <p className="card-footer-item">
            <a href={"/videos/" + props.id}>查看视频</a>
        </p>
    </footer>
)

export default VideoContainer